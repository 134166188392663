import React from 'react'
import { Card, CardContent } from '../../components/ui/card'
import { Badge } from '../../components/ui/badge'
import nvidia from '../../assets/nvidia.png'

const ProductSpec = ({ productGroup, setProduct, product }) => {
  return (
    <div className="flex md:flex-row flex-col gap-4">
      {productGroup?.products.map((option) => {
        return (
          <ProductOption
            key={option.pid}
            product={option}
            setProduct={setProduct}
            activeProduct={product}
          />
        )
      })}
    </div>
  )
}

const ProductOption = ({ product, setProduct, activeProduct }) => {
  let active = product.pid === activeProduct?.pid
  let unavailable = product.stocklevel <= 0

  const setActive = () => {
    setProduct(product)
  }

  return (
    <Card
      className={
        'w-full cursor-pointer' +
        (active ? ' bg-muted' : '') +
        (unavailable ? ' text-gray-400 cursor-not-allowed' : '')
      }
      onClick={!unavailable ? setActive : () => {}}
    >
      <CardContent className="pt-4 p-4">
        <div className="flex flex-row items-center">
          <img src={nvidia} className="h-4 mr-2" />
          <span className="text-l font-semibold">
            {product.meta.gpu_count}x {product.meta.gpu_name}
          </span>
        </div>

        <div className="flex flex-col gap-y-1 mt-1">
          <div className="text-sm">
            <span>CPU </span>
            <span className="font-semibold">
              {product.meta.cpu_cores} cores
            </span>
          </div>
          <div className="text-sm">
            <span>SSD </span>
            <span className="font-semibold">{product.meta.disk_space} GB</span>
          </div>
          <div className="text-sm">
            <span>RAM </span>
            <span className="font-semibold">{product.meta.ram} GB</span>
          </div>
        </div>

        {unavailable && (
          <Badge variant="red" className="my-2">
            Out of Stock
          </Badge>
        )}
        {!unavailable && (
          <Badge variant="blue" className="my-2">
            Instant Setup
          </Badge>
        )}
      </CardContent>
    </Card>
  )
}

export default ProductSpec
